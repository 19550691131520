* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-size: 50px !important;
  font-family: 'Comic Sans';
}

[class~='mobile'] {
  text-align: center;
}

.navbar-header {
  height: 15vh;
}

/* Logo */

img#logo {
  height: 190px;
  content: url('../img/lukas-carey-logo-white-text.png');
  position: absolute;
  top: -17px;
}

div#code-sydney .col-sm-12.text-right {
  text-align: center;
}

div#social-media-icons {
  display: flex;
  justify-content: center;
}

/* Fixes the issue with the footer */
.col-sm-6.text-right.custom-text-centre {
  position: absolute;
  top: 30px;
  right: 100px;
  width: 19vw;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .custom-text-centre {
    text-align: center;
  }

  /* Logo */
  .navbar-header {
    height: 7.9vh;
  }

  img#logo {
    height: 65px !important;
    content: url('../img/lukas-carey-logo-white-text.png') !important;
    box-shadow: 1px 1px 5px 5px rgb(0 0 0 / 20%),
      1px 1px 5px 5px rgb(0 0 0 / 19%) !important;
    filter: grayscale(0) !important;
    position: relative;
    margin: 2.5%;
  }

  /* Footer */
  footer#footer .container {
    width: 85vw;
  }

  footer#footer .col-sm-1 {
    float: left;
  }

  /* Fixes the issue with the footer */
  .col-sm-6.text-right.custom-text-centre {
    position: static;
    width: 80vw;
  }
}
