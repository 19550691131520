/* Contact Form */
.header.page {
  box-shadow: inset 10px 50px 300px 101px #000;
}
.content-contact-details {
  border: 2px solid #60758C;
  padding: 2rem;
  height: 180px; 
}

.contact-details-box {
  height: 100%;
}

span#mobile-number,
span.contact-details,
span#email {
  padding-left: 5%;
}

span.contact-details {
  padding-top: 5%;
}
span#mobile-number,
span#email {
  padding-top: 1%;
}

span#mobile-number,
span#email {
  font-size: 20px;
  padding-bottom: 3%;
  font-weight: bolder;
}

span.contact-details {
  display: block;
}

img.img-responsive {
  width: 100%;
}

.content-flex-left,
.content-flex-right {
  margin: 1%;
}

.wrapper {
  margin: 10% auto;
}

.wrapper > .title {
  margin: inherit;
  width: 55%;
  padding: 3%;
  border-bottom: 2px solid #60758C;
}

form {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}


textarea#outlined-textarea {
  height: 14vh;
  color: white;
}

.MuiInputBase-inputMultiline {
  padding: 1% !important;
}

div#form-title > h4 {
  width: 25% !important;
  margin-top: 5% !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
  color: white;
}

input#name,
input#email,
.MuiOutlinedInput-inputMultiline {
  color: white;
  padding: 2.50%;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-left,
.content-flex-left,
.flex-right,
.content-flex-right {
  width: 50%;
}

.flex-left {
  background-color: #60758c;
}

[class~=imgs] {
  filter: grayscale(100);
}

/* Autofill */
.MuiOutlinedInput-root {
  padding: 5px;
}

/* Border color */
.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.MuiInputBase-root {
  font-size: 15px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
  height: 20vh;
  width: 34.7vw;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.makeStyles-button-2.MuiButton-containedPrimary {
  width: 8vw;
  height: 7vh;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  margin-left: 1.5%;
}

.MuiButton-containedPrimary {
  background-color: #2A2D34 !important;
}
.MuiOutlinedInput-input {
  font-size: 15px !important;
}

.MuiButton-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.5rem;
}

label#name-label,label#email-label,
label#outlined-textarea-label {
    color: white;
    font-size: 15px;
}

input:-webkit-autofill {
  box-shadow: 0 0 10px 10px none inset;
}

/* Mobile First */
@media only screen and (max-width: 480px) {
  form {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 88%;
    margin-bottom: 10%;
  }

  .makeStyles-root-1 .MuiTextField-root {
    margin: 1%;
  }

  .content-flex-left,
  .content-flex-right {
    width: 100%;
  }

  .wrapper > .title {
    width: 75%;
  }

  div#form-title > h4 {
    width: 55% !important;
    margin-top: 7% !important;
  }

  h2 {
    font-size: 27px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
    height: 20vh;
    width: 88vw;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
  }
  .flex-left {
    width: 100%;
    background-color: #60758c;
    
  }

  .flex-right {
    width: 100%;
    background-color: turquoise;
   
  }
}
