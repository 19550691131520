img#lukas {
  filter: grayscale(100%);
  width: 200px !important;
  border-radius: 10px !important;
  margin-top: 10%;
}

.content-title {
  margin: 4% 0;
  border-bottom: 2px solid;
}

/* Menu */
section#menu {
  width: 50% !important;
  margin: 0 auto;
}

.col-md-4 {
  width: 33.33333333%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}

div#profile {
  width: 50%;
  margin: 0 auto;
}

.MuiTabs-root {
  background-color: #212121;
}

.MuiTab-textColorPrimary {
  color: #fff !important;
}

.PrivateTabIndicator-colorPrimary-3 {
  background-color: #60758c !important;
}

.works h4 {
  color: white;
}

.research-initiatives a, a:hover, a:focus, a:active,
.seminar a, a:hover, a:focus, a:active {
  color: white !important;
}

/* Menu Font */
ul.research-initiatives,
ul.education-history,
ul.works,
ul.seminar {
  padding-left: 15px;
  font-size: medium;
}

/* Mobile max-width: 360px */
@media screen and (max-width: 600px) {
  .content-title > h2 {
    text-align: center;
  }

  .row {
    padding: 2% !important;
  }

  img#lukas {
    width: 100% !important;
    border-radius: 25px !important;
    margin-top: 10%;
    padding: 4%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .col-xs-3 {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
  }

  .col-md-6 > p {
    padding: 3%;
  }

  /* Menu */
  section#menu {
    width: 95% !important;
    margin: 0 auto;
  }

  h2 {
    text-align: center !important;
    font-size: 25px !important;
  }

  .col-md-4 {
    width: 100%;
    padding: 0;
  }

  ul.research-initiatives,
  ul.education-history,
  ul.works,
  ul.seminar {
    padding-left: 15px;
  }

  div#bio-description {
    width: 80vw;
    margin: 0 auto;
    text-align: center;
  }
}
