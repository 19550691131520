.landing {
  width: 100%;
  height: 100vh;
}

.intro .vertical-center {
  position: absolute;
  top: 40rem;
}

h1.slider-caption {
  text-shadow: 5px 5px 10px #60758c;
}

[id~='intro-slider'] {
  padding-left: inherit;
  padding-right: inherit;
}

/* Ken Burns Effect */
div.intro-slider {
    overflow-x: hidden;
    overflow-y: hidden;
}  

div.intro-slider div {
  width: 100%;
  animation: move 40s ease;
  overflow-x: hidden;

  -ms-animation: move 40s ease;
  -webkit-animation: move 40s ease;
  -moz-animation: move 40s ease;
  position: absolute;
}

@keyframes move {
    0% {
      -webkit-transform-origin: bottom left;
      -moz-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      -o-transform-origin: bottom left;
      transform-origin: bottom left;
      transform: scale(1.0);

      -ms-transform: scale(1.0);
      
      
      -webkit-transform: scale(1.0);
      
      
      -o-transform: scale(1.0);
      
      
      -moz-transform: scale(1.0);
      
    }
    100% {
      transform: scale(1.2);
      -ms-transform: scale(1.2);
      
      
      -webkit-transform: scale(1.2);
      
      
      -o-transform: scale(1.2);
      
      
      -moz-transform: scale(1.2);
      
    }
  }